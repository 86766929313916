<div class="contact_main text-white">
  <h2>resetta la password</h2>
  <div class="form_container">
    <div class="inputs">
      <form [formGroup]="form">
        <mat-form-field
          appearance="fill"
          class="w-full h-fit p-2 max-[600px]:w-full"
        >
          <mat-label>Vecchia Password</mat-label>
          <input
            formControlName="oldPassword"
            matInput
            type="{{ oldPasswordHide ? 'password' : 'text' }}"
            placeholder="Vecchia Password"
          />
          <mat-icon matPrefix>vpn_key</mat-icon>
          <mat-icon
            (click)="oldPasswordHide = !oldPasswordHide"
            matSuffix
            >{{
              oldPasswordHide ? "visibility_off" : "visibility"
            }}</mat-icon
          >
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-1/2 p-2 max-[600px]:w-full">
          <mat-label>Password</mat-label>
          <input
            matInput
            formControlName="newPassword"
            type="{{ passwordHide ? 'password' : 'text' }}"
            placeholder="Password"
          />
          <mat-icon matPrefix>lock</mat-icon>
          <mat-icon (click)="passwordHide = !passwordHide" matSuffix>{{
            passwordHide ? "visibility_off" : "visibility"
          }}</mat-icon>

          <mat-hint
            align="start"
            class="text-red-600"
            *ngIf="
              form.hasError('uppercase') &&
              form.get('newPassword')?.dirty &&
              form.get('newPassword')?.touched
            "
          >
            Devi almeno inserire una lettera maiuscola
          </mat-hint>

          <mat-hint
            align="end"
            class="text-red-600"
            *ngIf="
              form.hasError('minlength') &&
              form.get('newPassword')?.dirty &&
              form.get('newPassword')?.touched
            "
          >
            La password deve essere di almeno 8 caratteri
          </mat-hint>
        </mat-form-field>

        <mat-form-field
          appearance="fill"
          class="w-1/2 h-fit p-2 max-[600px]:w-full"
        >
          <mat-label>Conferma Password</mat-label>
          <input
            formControlName="newPasswordConfirm"
            matInput
            type="{{ confirmPasswordHide ? 'password' : 'text' }}"
            placeholder="Conferma Password"
          />
          <mat-icon matPrefix>lock</mat-icon>
          <mat-icon
            (click)="confirmPasswordHide = !confirmPasswordHide"
            matSuffix
            >{{
              confirmPasswordHide ? "visibility_off" : "visibility"
            }}</mat-icon
          >
          <mat-hint
            class="text-red-600"
            *ngIf="
              form.hasError('passwordMismatch') &&
              form.get('newPasswordConfirm')?.dirty &&
              form.get('newPasswordConfirm')?.touched
            "
          >
            Le password non coincidono
          </mat-hint>
        </mat-form-field>
      </form>
    </div>

    <div class="buttons">
      <button
        mat-raised-button
        color="warn"
        [disabled]="form.invalid"
        (click)="submit()"
      >
        Invia
      </button>
      <button mat-raised-button color="orange" (click)="closepopup()">
        Chiudi
      </button>
    </div>
  </div>
</div>
