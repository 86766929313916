import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { register } from 'swiper/element/bundle';
import { EventiService } from '../../services/eventi.service';
import { Eventi } from '../../interfaces/eventi';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { CommonModule, DatePipe } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CalendarOptions } from '@fullcalendar/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { EventFormComponent } from '../../components/event-form/event-form.component';
import { UserDetail } from '../../interfaces/user-detail';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { FooterComponent } from '../../components/footer/footer.component';
import {
  MAT_DATE_LOCALE,
  provideNativeDateAdapter,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { error } from 'console';
import { MatTooltipModule } from '@angular/material/tooltip';

register();

@Component({
  selector: 'app-eventivisual',
  standalone: true,
  providers: [
    provideNativeDateAdapter(),
    DatePipe,
    MatDatepickerModule,
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
  ],
  imports: [
    CommonModule,
    FullCalendarModule,
    DatePipe,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatIconModule,
    FooterComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './eventi.component.html',
  styleUrl: './eventi.component.scss',
})
export class EventiComponent implements OnInit {
  apiUrl: any = environment.apiUrl;
  slideforView = '3';
  clean: boolean = false;
  canLogEvent: boolean = true;
  isAlready: boolean = false;
  errorMessage = '';
  singleEvent?: Eventi;
  eventiCalendar!: [];
  events$?: Eventi[];
  user?: UserDetail;

  readonly eventService = inject(EventiService);
  readonly authService = inject(AuthService);
  readonly matSnackbar = inject(MatSnackBar);

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    weekends: true,
    plugins: [dayGridPlugin, interactionPlugin],
    eventClick: (arg) => this.handleEventClick(arg),
    locales: [{ code: 'it' }],
    events: this.events$,
  };

  constructor(
    private service: EventiService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.loadEvent();
  }

  ngOnInit(): void {
    
    this.authService.getDetail().subscribe((item: UserDetail) => {
      this.user = item;

      if (!item.abbonamentoAttivo) {
        this.canLogEvent = false;
      }
    });

    if (this.isMobile()) this.slideforView = '1';
  }

  loadEvent() {
    const events: any = [];
    this.eventService.getAllEventi().subscribe((res) => {
      this.events$ = res.reverse();
      var data: any = [];
      res.forEach((e: any) => {
        data.push({
          title: e.nome,
          date: this.parseDMY(e.data),
          id: e.id,
          backgroundColor: 'rgb(237, 146, 0)',
          borderColor: 'rgb(237, 146, 0)',
        });
      });

      this.calendarOptions.events = data;
    });
  }

  handleEventClick(arg: any) {
    document.getElementById('eventoshow')?.classList.add('is-active');

    this.eventService
      .isInEvent(this.user?.id!, arg.event.id)
      .subscribe((res) => {
        next: {
          if (res.isSuccess) this.isAlready = res.isSuccess;
        }
        error: {
        }
      });

    this.singleEvent = this.events$?.find((x) => x.id == arg.event.id);

    this.clean = true;
  }

  openDetail(ID: any) {}

  isMobile() {
    const regex =
      /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
  }

  openForm(id: any) {
    if (this.canLogEvent) this.Openpopup(id, 'Form evento', EventFormComponent);
    else {
      this.matSnackbar.open('DEVI COMPRARE UN ABBONAMENTO', 'chiudi', {
        duration: Infinity,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  Openpopup(code: any, title: any, component: any) {
    var _popup = this.dialog.open(component, {
      // panelClass: 'all-dialog-start',
      maxWidth: 'auto',
      maxHeight: '100bvh',
      enterAnimationDuration: '1000ms',
      exitAnimationDuration: '500ms',
      disableClose: false,
      closeOnNavigation: true,
      data: {
        title: title,
        id: code,
      },
    });
    _popup.afterClosed().subscribe((item) => {});
  }

  scroll(el: HTMLElement, eId: string) {
    document.getElementById('eventoshow')?.classList.add('is-active');

    this.singleEvent = this.events$?.find((x) => x.id == eId);
    this.clean = true;

    el.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }

  parseDMY = (s: any) => {
    let [d, m, y] = s.split(/\D/);
    return new Date(y, m - 1, d);
  };
}
