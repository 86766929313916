<div class="animation-wrapper">
  <div class="particle particle-1"></div>
  <div class="particle particle-2"></div>
  <div class="particle particle-3"></div>
  <div class="particle particle-4"></div>
</div>

<div class="h-fit flex flex-col items-center">
  <div
    class="container mx-auto my8 p-8 max-md:p-2 shadow-md rounded-md max-w-md"
  >
    <h1 class="text-3xl text-white font-bold mb-4 text-center">
      Il mio Account
    </h1>
    <div *ngIf="accountDetail$ | async as user">
      <div class="text-center">
        <div
          class="rounded-full bg-orange-500 my-4 text-4xl w-24 h-24 inline-flex items-center justify-center font-bold text-white"
        >
          {{ user.email![0] | uppercase }}
        </div>
      </div>
      <hr class="py-4 border-slate-400" />

      <mat-tab-group
        mat-stretch-tabs="true"
        mat-align-tabs="center"
        [selectedIndex]="selectedTabs.value"
        [dynamicHeight]="true"
      >
        <mat-tab label="Info">
          <div class="grid grid-cols-2 gap-4 p-5 text-white">
            <div class="font-bold">Nome</div>
            <div class="font-medium" *ngIf="!isModify">
              {{ user.nome | titlecase }}
            </div>
            <mat-form-field class="inputs-modify" *ngIf="isModify">
              <mat-label>Nome</mat-label>
              <input matInput value="{{ user.nome }}" [formControl]="nome" />
            </mat-form-field>

            <div class="font-bold">Cognome</div>
            <div class="font-medium" *ngIf="!isModify">
              {{ user.cognome | titlecase }}
            </div>
            <mat-form-field class="inputs-modify" *ngIf="isModify">
              <mat-label>Cognome</mat-label>
              <input
                matInput
                value="{{ user.cognome }}"
                [formControl]="cognome"
              />
            </mat-form-field>

            <div class="font-bold">Data di nascita</div>
            <div class="font-medium" *ngIf="!isModify">
              {{ user.age }}
            </div>
            <mat-form-field class="inputs-modify" *ngIf="isModify">
              <mat-label>Data di nascita</mat-label>
              <input matInput [matDatepicker]="picker" [formControl]="age" />
              <mat-datepicker-toggle
                matPrefix
                style="color: black"
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            @if(user.roles!.includes('Admin')) {
            <div class="font-bold">Minorenne</div>
            <div class="font-medium">
              {{ user.minorenne ? "SI" : "Maggiorenne" }}
            </div>

            <div class="font-bold">Ultima modifica</div>
            <div class="font-medium">
              {{ user.lastModifyAt }}
            </div>

            <div class="font-bold">Creato il :</div>
            <div class="font-medium">
              {{ user.createdAt }}
            </div>

            <div class="font-bold">Stato</div>
            <div class="font-medium">{{ statoEnum[user.stato] }}</div>

            <div class="font-bold">Roles</div>

            <div>
              <ul class="flex gap-1 text-xs">
                <li
                  *ngFor="let role of user.roles"
                  class="bg-green-700 text-white rounded-md py-1 px-2"
                >
                  {{ role }}
                </li>
              </ul>
            </div>
            }

            <div class="font-bold">Telefono</div>
            <div class="font-medium" *ngIf="!isModify">
              {{ user.phoneNumber || "-" }}
            </div>
            <mat-form-field class="inputs-modify" *ngIf="isModify">
              <mat-label>Telefono</mat-label>
              <input
                matInput
                value="{{ user.phoneNumber }}"
                [formControl]="phoneNumber"
              />
            </mat-form-field>

            <div class="font-bold">Via</div>
            <div class="font-medium" *ngIf="!isModify">
              {{ user.via + " " + user.cap }}<br />
              {{ user.paese + " - " + user.provincia + " - " + user.comune }}
            </div>

            <div class="flex flex-col w-full" *ngIf="isModify">
              <mat-form-field class="inputs-modify" *ngIf="isModify">
                <mat-label>Via</mat-label>
                <input
                  matInput
                  value="{{ user.via }}"
                  [formControl]="via"
                />
              </mat-form-field>
              <mat-form-field class="inputs-modify" *ngIf="isModify">
                <mat-label>Cap</mat-label>
                <input
                  matInput
                  value="{{ user.cap }}"
                  [formControl]="cap"
                />
              </mat-form-field>
              <mat-form-field class="inputs-modify" *ngIf="isModify">
                <mat-label>Paese</mat-label>
                <input
                  matInput
                  value="{{ user.paese }}"
                  [formControl]="paese"
                />
              </mat-form-field>
              <mat-form-field class="inputs-modify" *ngIf="isModify">
                <mat-label>Provincia</mat-label>
                <input
                  matInput
                  value="{{ user.provincia }}"
                  [formControl]="provincia"
                />
              </mat-form-field>
              <mat-form-field class="inputs-modify" *ngIf="isModify">
                <mat-label>Comune</mat-label>
                <input
                  matInput
                  value="{{ user.comune }}"
                  [formControl]="comune"
                />
              </mat-form-field>
            </div>

            @if(user.abbonamentoAttivo) {
            <div class="font-bold">Scadenza abbonamento</div>
            <div class="font-medium">1/1/{{ yearAbbo }}</div>
            } @if(user.abbonamentoTp != null){
            <div class="font-bold">Tipologia di abbonamento</div>
            <div class="font-medium">{{ user.abbonamentoTp }}</div>
            }
          </div>
        </mat-tab>

        <mat-tab label="Sicurezza">
          <div class="grid grid-cols-2 gap-4 p-5 text-white">
            <div class="font-bold">Email</div>
            <div class="font-medium max-md:text-sm" *ngIf="!isModify">
              {{ user.email }}
            </div>
            <mat-form-field class="inputs-modify" *ngIf="isModify">
              <mat-label>Email</mat-label>
              <input matInput value="{{ user.email }}" [formControl]="email" />
            </mat-form-field>

            <div class="font-bold">
              Cambia <br />
              password
            </div>
            <div class="font-medium">
              <a
                class="changePsw text-orange-400 cursor-pointer hover:text-orange-600"
                (click)="resetPsw(user.id!)"
                >Cambia</a
              >
            </div>

            <div class="font-bold">Modifica utente</div>
            <div class="font-medium">
              <button
                [disabled]="!isCanModify(user.lastModifyAt!)"
                *ngIf="!isModify"
                (click)="openModifyUser(user)"
                class="changePsw cursor-pointer text-orange-400 hover:text-orange-600 disabled:text-zinc-600 disabled:hover:text-zinc-800"
              >
                Modifica
              </button>
              <button
                [disabled]="!isCanModify(user.lastModifyAt!)"
                *ngIf="isModify"
                (click)="closeModifyUser()"
                class="changePsw cursor-pointer text-red-400 hover:text-red-600 disabled:text-zinc-600 disabled:hover:text-zinc-800"
              >
                Chiudi
              </button>
              <p
                class="text-xs text-red-400"
                *ngIf="!isCanModify(user.lastModifyAt!)"
              >
                Puoi modificare l'utente solo 1 volta al mese
              </p>
            </div>

            <div class="font-bold" *ngIf="isModify">Conferma modifiche</div>
            <div class="font-medium" *ngIf="isModify">
              <a
                (click)="confirmModifyUser()"
                class="changePsw text-green-400 cursor-pointer hover:text-green-600"
                >Conferma</a
              >
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Utilità">
          <div class="grid grid-cols-1 gap-4 py-5 text-white">
            <div
              class="abbonamento w-full h-full"
              *ngIf="user.abbonamentoAttivo"
            >
              <div class="card" *ngIf="Subscription$ != null">
                <div class="card-inner">
                  <div class="front">
                    <img
                      draggable="false"
                      src="assets/images/ForPages/bmwE46-transparent.webp"
                      class="map-img"
                    />
                    <div class="row"></div>
                    <div class="row card-no">
                      <p>
                        {{
                          Subscription$.publicCardId != null
                            ? Subscription$.publicCardId
                            : "Gratuito"
                        }}
                      </p>
                    </div>
                    <div class="row card-holder">
                      <p>PROPRIETARIO</p>
                      <p>VALIDA FINO</p>
                    </div>
                    <div class="row name">
                      <p>
                        {{ user.nome | uppercase }}
                        {{ user.cognome | uppercase }}
                      </p>
                      <p>1 / {{ yearAbbo }}</p>
                    </div>
                  </div>
                  <div class="back">
                    <img
                      draggable="false"
                      src="assets/images/ForPages/bmwE46-transparent.webp"
                      class="map-img"
                      style="
                        transform: scaleX(-1);
                        -webkit-transform: scaleX(-1);
                      "
                    />
                    <div class="bar"></div>
                    <div class="row card-text">
                      <p>
                        Questa è una carta non valida all'utilizzo, ma solo allo
                        scopo di visualizzazione di dati
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="registrati text-center" *ngIf="!user.abbonamentoAttivo">
              <h1>Non sei ancora tesserato</h1>
              <a
                class="abbolink text-orange-400 cursor-pointer hover:text-orange-600"
                [routerLink]="['/abbonamenti']"
              >
                Entra in famiglia</a
              >
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
