<div class="main">
  <h2 mat-dialog-title>Modifica</h2>

  <mat-dialog-content
    >Vuoi confermare le modifiche all'utente?</mat-dialog-content
  >

  <mat-dialog-actions>
    <button mat-raised-button color="warn" mat-dialog-close>Chiudi</button>
    <button mat-raised-button color="orange" [mat-dialog-close]="true">
      Conferma
    </button>
  </mat-dialog-actions>
</div>
