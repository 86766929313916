import { Component, Inject, inject, LOCALE_ID, OnInit } from '@angular/core';
import { EventiService } from '../../services/eventi.service';
import { Eventi } from '../../interfaces/eventi';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MAT_DATE_LOCALE,
  provideNativeDateAdapter,
} from '@angular/material/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AuthService } from '../../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-event-form',
  standalone: true,
  providers: [
    provideNativeDateAdapter(),
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
  ],
  imports: [
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatToolbarModule,
    MatDialogModule,
    CommonModule,
    MatDatepickerModule,
  ],
  templateUrl: './event-form.component.html',
  styleUrl: './event-form.component.scss',
})
export class EventFormComponent implements OnInit {
  form!: FormGroup;

  editData: any;
  eventUse!: Eventi;
  eventManager = inject(EventiService);
  userManager = inject(AuthService);
  matSnackBar = inject(MatSnackBar);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<EventFormComponent>,
    private fb: FormBuilder,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      userID: new FormControl(''),
      email: new FormControl({ value: '', disabled: true }),
      eventID: new FormControl({ value: '', disabled: true }),
      data: new FormControl({ value: '', disabled: true }),
      persone: new FormControl(''),
      macchine: new FormControl(''),
    });
    if (this.data?.id != null) {
      this.setpopupdata(this.data.id);
    }
  }

  setpopupdata(id: string) {
    this.eventManager.getEvento(id).subscribe((item) => {
      this.editData = item;
      if (this.editData.data != null) {
        let data = this.editData.data.split('/');
        this.editData.data = new Date(Date.UTC(data[2], data[1] - 1, data[0]));
      }
      if (item != undefined) {
        this.userManager.getDetail().subscribe((user) => {
          this.form.setValue({
            userID: user.id,
            email: user.email,
            eventID: id,
            data: this.editData.data,
            persone: 1,
            macchine: 1,
          });
        });
      } else {
        this.ref.close();
      }
    });
  }

  saveForm() {
    this.eventManager
      .sendEventEntering(this.form.getRawValue())
      .subscribe((res) => {
        this.matSnackBar.open(res.message, 'Chiudi', {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        this.ref.close('Chiusura automatica');
      });
  }
}
