<div class="main">
  <div class="header">
    <h1><span class="text-white font-bold text-2xl pt-4">Registrati: </span> <br /> {{ editData?.nome != null ? editData?.nome : "" }}</h1>
  </div>

  <form [formGroup]="form" (ngSubmit)="saveForm()">
    <div
      mat-dialog-content
      class="content !flex justify-center flex-wrap gap-3"
    >
      <div class="flex gap-3 mobile">
        <mat-form-field class="w-full">
          <mat-label>Email</mat-label>
          <input
            matInput
            formControlName="email"
            [disabled]="form.value?.email"
          />
        </mat-form-field>

        <mat-form-field class="w-full">
          <mat-label>Data evento</mat-label>
          <input
            matInput
            [matDatepicker]="data"
            formControlName="data"
            readonly
          />
          
          <mat-datepicker-toggle
            matIconSuffix
            [for]="data"
          ></mat-datepicker-toggle>
          <mat-datepicker #data></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="flex gap-3 mobile">
        <mat-form-field class="w-full">
          <mat-label>Costi per auto</mat-label>
          <input
            matInput
            [value]="editData.costiAuto"
            [disabled]="true"
          />
        </mat-form-field>

        <mat-form-field class="w-full">
          <mat-label>Passeggeri</mat-label>
          <input
            matInput
            [value]="editData.personeIncluse"
            [disabled]="true"
          />
        </mat-form-field>
      </div>

      <div class="flex gap-3 justify-center mobile-person">
        <mat-form-field [style.width.%]="30" class="inner">
          <mat-label>passeggeri</mat-label>
          <input matInput type="number" formControlName="persone" />
        </mat-form-field>

        <mat-form-field [style.width.%]="30" class="inner">
          <mat-label>Macchine</mat-label>
          <input matInput type="number" formControlName="persone" />
        </mat-form-field>
      </div>
    </div>

    <div mat-dialog-actions class="buttons flex !justify-end !p-4 !mx-8 ">
      <button mat-raised-button type="submit" color="orange">Registrati</button>
      <!-- <button mat-raised-button [mat-dialog-close]="closemessage" color="accent">Cancel</button> -->
    </div>
  </form>
</div>
