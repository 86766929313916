<div class="animation-wrapper">
  <div class="particle particle-1"></div>
  <div class="particle particle-2"></div>
  <div class="particle particle-3"></div>
  <div class="particle particle-4"></div>
</div>

<section class="Gratuito pt-5" [hidden]="!disablingRoute.includes('Gratuito')">
  <h1>Follower</h1>
  <!-- <p>Questo è l'abbonamento base per poter fare tutto ciò</p> -->
  <div class="selector">
    <div class="minibox">
      <h2>Registrazione</h2>
      <div>
        <h3 class="text-base">Con la registrazione potrai accedere all'area privata del sito,</h3>
        <p class="text-base">
          potrai iscriverti agli eventi come pilota o passeggero (taxi drift)
        </p>

        @if(!isLoggedIn()){
        <button mat-button class="mx-5" color="orange" (click)="register()">
          Registrati
        </button>
        }
      </div>

      <img
        class="selectDisable"
        src="assets/images/ForPages/nissanSkyline.webp"
        alt=""
      />
    </div>

    <!-- <div class="minibox" hidden>
      <h2>Spettatore</h2>
      <p>
        potrai partecipare ai nostri eventi, come spettatore o partecipando al taxi drift,
      </p>
    </div> -->

    <!-- <div class="minibox" hidden>
      <h2>Media</h2>
      <p>
        Durante gli eventi è possibile trovare diversi operatori che effettuano
        foto e video
      </p>
    </div> -->
  </div>
</section>

<section class="Base pt-5" [hidden]="!disablingRoute.includes('Base')">
  <h1>Tesserato</h1>
  <!-- <p>Questo è l'abbonamento base per poter fare tutto ciò</p> -->
  <div class="selector">
    <div class="minibox">
      <h2>La Tessera</h2>
      <div class="flex flex-col">
        <h3 class="text-sm">
          L'abbonamento include il tesseramento alla FAMS. <br />
          Per poter accedere alle nostre attività è obbligatorio tesserarsi
        </h3>

        <div
          class="max-md:relative max-md:items-center max-md:my-2 absolute right-5 top-0 flex flex-col h-full justify-around"
        >
          <p class="md:self-end prezzo relative">15€ - <span>Annuale</span></p>

          @if(isLoggedIn()){ @if(needSubscribe()){
          <button
            mat-button
            class="m-auto my-2 text-end relative w-3/5"
            color="orange"
            (click)="subscribe()"
          >
            Richiedi Affiliazione
          </button>
          } } @else {
          <button mat-button class="mx-12 w-fit self-end" color="orange" (click)="register()">
            Registrati
          </button>
          }
        </div>
      </div>

      <img
        class="selectDisable"
        src="assets/images/ForPages/bmwM2.webp"
        alt=""
      />
    </div>

    <div class="minibox">
      <h2>Attività</h2>
      <p>
        Corsi drift - Allenamenti - Gare<br />
        Raduni statici e dinamici
      </p>
      <!-- <button mat-raised-button routerLink="/eventivisual" color="orange">visualizza eventi</button> -->
    </div>

    <div class="minibox">
      <h2>Media</h2>
      <p>
        Verranno caricate in questa sezione <br />
        le foto e i video degli eventi
      </p>
    </div>
  </div>
</section>

<section class="Agonistico pt-5" hidden>
  <h1>Abbonamento Agonistico</h1>
  <!-- <p>Questo è l'abbonamento base per poter fare tutto ciò</p> -->
  <div class="container">
    <div class="box">
      <h2>Gare</h2>
      <p>
        L'agonismo , grazie al nostro team , con un possibile supporto del team
        per gare con meccanici, pezzi e auto
      </p>
    </div>
    <div class="box">
      <h2>Gare</h2>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo nostrum
        modi fugit officia nesciunt sed consectetur vero consequuntur quia
        facere.
      </p>
    </div>
    <div class="box">
      <h2>Gare</h2>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo,
        architecto.
      </p>
      <img
        class="selectDisable"
        src="assets/images/ForPages/races.webp"
        alt=""
      />
    </div>
  </div>
</section>

<app-footer />
