import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { Component, inject, Inject } from '@angular/core';
import {
  ReactiveFormsModule,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  provideNativeDateAdapter,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../services/auth.service';
import { AbbonamentoComponent } from '../abbonamento/abbonamento.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { debug } from 'console';

const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY', // this is how your date will be parsed from Input
  },
  display: {
    dateInput: 'DD/MM/YYYY', // this is how your date will get displayed on the Input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-subscribe',
  standalone: true,
  providers: [
    provideNativeDateAdapter(),
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT },
  ],
  imports: [
    ReactiveFormsModule,
    MatFormField,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    AsyncPipe,
    CommonModule,
  ],
  templateUrl: './subscribe.component.html',
  styleUrl: './subscribe.component.scss',
})
export class SubscribeComponent {
  title: string = 'Sottoscrivi';
  matSnackBar = inject(MatSnackBar);
  authService = inject(AuthService);

  inputData: any;
  editData: any;

  form!: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<AbbonamentoComponent>,
    private fb: FormBuilder,
    private service: AuthService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.inputData = this.data;

    this.form = this.fb.group({
      userID: ['', Validators.required],
      nome: ['', Validators.required],
      cognome: ['', Validators.required],
      cdFiscale: ['', [Validators.required, Validators.minLength(6)]],
      eta: ['', Validators.required],
      acceptAgreement: ['', Validators.requiredTrue],
    });

    this.form.controls['userID'].setValue(this.inputData.code);
  }

  closepopup() {
    this.ref.close('Chiusura automatica');
  }

  sendconfirm() {
    var user = this.authService.getUserDetail();

    if (user?.abbonamento == 'false')
      if (this.form.valid) {
        const formatDate = this.datePipe.transform(
          this.form.controls['eta'].value,
          'dd/MM/yyyy'
        );
        this.form.controls['eta'].setValue(formatDate);

        this.authService
          .createFreeMembership(this.form.value)
          .subscribe((res) => {
            this.matSnackBar.open(res.message, 'Chiudi', {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });

            if (res.isSuccess) {
              this.ref.close();
            }
          });
      } else
        this.matSnackBar.open('Possiedi già un abbonamento attivo', 'Chiudi', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
  }
}
