import { Component, Inject, inject } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { AsyncPipe, CommonModule } from '@angular/common';

@Component({
  selector: 'app-reset-psw',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    CommonModule,
    AsyncPipe,
  ],
  templateUrl: './reset-psw.component.html',
  styleUrl: './reset-psw.component.scss',
})
export class ResetPswComponent {
  form!: FormGroup;
  select!: '';
  authService = inject(AuthService);
  matSnackBar = inject(MatSnackBar);

  confirmPasswordHide: boolean = true;
  passwordHide: boolean = true;
  oldPasswordHide: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private ref: MatDialogRef<ResetPswComponent>
  ) {}


  ngOnInit(): void {
    if (this.data != null) {
      console.log(this.data);
    }
    this.form = this.fb.group(
      {
        oldPassword: ['', [Validators.required]],
        newPassword: ['', [Validators.required]],
        newPasswordConfirm: ['', [Validators.required]],
      },
      {
        validators: [
          this.passwordMatchValidator,
          this.passwordUpperCase,
          this.passwordminlenght,
        ],
      }
    );
  }

  submit() {
    if (this.form.valid) {
      this.form.addControl('userID', String);
      this.form.controls['userID'].setValue(this.data.id);

      this.authService.changePsw(this.form.value);
    }
  }

  closepopup() {
    this.ref.close('Chiusura automatica');
  }

  private passwordMatchValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const password = control.get('newPassword')?.value;
    const confirmPassword = control.get('newPasswordConfirm')?.value;

    if (password !== confirmPassword) {
      return { passwordMismatch: true };
    }

    return null;
  }

  private passwordUpperCase(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const password = control.get('newPassword')?.value;

    if (!/[A-Z]/.test(password)) {
      return { uppercase: true };
    }

    return null;
  }

  private passwordminlenght(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const password = control.get('newPassword')?.value;

    if (password.length < 8) {
      return { minlength: true };
    }

    return null;
  }
}
