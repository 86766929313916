<div class="title overflow-hidden text-white py-6 m-auto w-fit font-bold text-xl">
  <h1>
    Dati Personali trattati per le seguenti finalità e utilizzando i seguenti
    servizi:
  </h1>
</div>
<div class="main table text-white">
  <div class="box">
    <div class="header flex">
      <div class="left">
        <mat-icon class="mat-18">email</mat-icon>
      </div>
      <div class="right flex-col">
        <h1>Contattare l'utente</h1>
        <h2>Mailing list o newsletter</h2>
        <p>Dati personali: email, nome, cognome, data di nascita</p>
      </div>
    </div>
  </div>

  <div class="box">
    <div class="header flex">
      <div class="left">
        <mat-icon class="mat-18">campaign</mat-icon>
      </div>
      <div class="right flex-col">
        <h1>Gestione contatti e invio di messaggi</h1>
        <h2>MailerLite</h2>
        <p>Dati personali: email</p>
      </div>
    </div>
  </div>

  <div class="box">
    <div class="header flex">
      <div class="left">
        <mat-icon class="mat-18">co_present</mat-icon>
      </div>
      <div class="right flex-col">
        <h1>Pubblicità</h1>
        <h2>SMdrift team</h2>
        <p>Dati personali: nome, cognome, email, data di nascita</p>
      </div>
    </div>
  </div>

  <div class="box">
    <div class="header flex">
      <div class="left">
        <mat-icon class="mat-18">cell_wifi</mat-icon>
      </div>
      <div class="right flex-col">
        <h1>Ottimizzazione e distribuzione del traffico</h1>
        <h2>Hostinger</h2>
        <p>
          Dati personali: Varie tipologie di Dati secondo quanto specificato
          dalla privacy policy del servizio
        </p>
      </div>
    </div>
  </div>

  <div class="box">
    <div class="header flex">
      <div class="left">
        <mat-icon class="mat-18">supervisor_account</mat-icon>
      </div>
      <div class="right flex-col">
        <h1>Registrazione ed autenticazione</h1>
        <h2>Registrazione diretta</h2>
        <p>
          Dati Personali: nome; cognome; data di nascita; email; ID Utente;
          immagine; immagine di profilo; indirizzo; indirizzo di fatturazione;
          codice fiscale; indirizzo di spedizione; lingua; nazione; 
          Nickname su diversi social (Facebook / Instagram); numero civico; 
          numero di dipendenti; numero di telefono; password; prefisso; sesso; sito web;
          stato; username; varie tipologie di Dati
        </p>
      </div>
    </div>
  </div>

  <div class="box">
    <div class="header flex">
      <div class="left">
        <mat-icon class="mat-18">attachment</mat-icon>
      </div>
      <div class="right flex-col">
        <h1>Visualizzazione di contenuti da piattaforme esterne</h1>
        <h2>Widget Google Maps, Widget Instagram e Widget Video YouTube</h2>
        <p>Dati Personali: Cookie; Dati di utilizzo</p>
        <h2>Widget Video YouTube senza cookie e <br/> Font Awesome</h2>
        <p>Dati Personali: Dati di utilizzo</p>
        <h2>Google Fonts</h2>
        <p>
          Dati Personali: Dati di utilizzo; varie tipologie di Dati secondo
          quanto specificato dalla privacy policy del servizio
        </p>
        <h2>Angular Material</h2>
        <p>
          Dati Personali: Dati di utilizzo; varie tipologie di Dati secondo
          quanto specificato dalla privacy policy del servizio
        </p>
      </div>
    </div>
  </div>

  <div class="box">
    <div class="header flex">
      <div class="left">
        <mat-icon class="mat-18">query_stats</mat-icon>
      </div>
      <div class="right flex-col">
        <h1>Visualizzazione di contenuti e statistiche</h1>
        <h2>Google Fonts</h2>
        <h2>Google Analitics (Universal Analytics)</h2>
        <p>
          Dati personali: Varie tipologie di Dati secondo quanto specificato
          dalla privacy policy del servizio
        </p>
      </div>
    </div>
  </div>
  <div class="box">
    <div class="header flex">
      <div class="left">
        <mat-icon class="mat-18">folder_copy</mat-icon>
      </div>
      <div class="right flex-col">
        <h1>Servizi di piattaforma e hosting</h1>
        <h2>SM drift team</h2>
        <p>
          Dati Personali: dati di profilazione e utilizzo utenti relative al login / 
          registrazione utenti 
        </p>
        <h2>Hostinger</h2>
        <p>
          Dati Personali: varie tipologie di Dati secondo quanto specificato
          dalla privacy policy del servizio
        </p>
      </div>
    </div>
  </div>
</div>
<div class="title text-white py-6 m-auto w-fit font-bold text-xl">
  <h1>Informazioni di contatto</h1>
</div>
<div class="owner overflow-hidden">
  <div class="container flex text-white">
    <div class="left">
      <mat-icon class="mat-18">account_circle</mat-icon>
    </div>
    <div class="right">
      <h1>Titolare del Trattamento dei Dati</h1>
      <h2>SM drift club</h2>
      <p>
        Via la viviana, 29 <br />
        47890 San Marino
      </p>
      <h2>indirizzo email del Titolare:</h2>
      <p>{{ email }}</p>
      <h2>numero di telefono:</h2>
      <p>366 196 6368</p>
    </div>
  </div>
</div>
<div class="bottom fixed bottom-4 text-white">
  <mat-icon class="cursor-pointer" (click)="close()">exit_to_app</mat-icon>
  <p class="cursor-pointer" (click)="close()">chiudi</p>
</div>