import { Component, Inject, inject, Injector, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { CommonModule, DatePipe } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { StatoEnum, UserDetail } from '../../interfaces/user-detail';
import { MatIcon } from '@angular/material/icon';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { ResetPswComponent } from '../../components/reset-psw/reset-psw.component';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Membership } from '../../interfaces/membership';
import {
  MAT_DATE_LOCALE,
  provideNativeDateAdapter,
} from '@angular/material/core';
import { UserModifyComponent } from '../../components/user-modify/user-modify.component';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-account',
  standalone: true,
  providers: [
    provideNativeDateAdapter(),
    DatePipe,
    MatDatepickerModule,
    { provide: MAT_DATE_LOCALE, useValue: 'it-it' },
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    FormsModule,
    DatePipe,
    MatTabsModule,
    MatIcon,
    UserModifyComponent,
    RouterModule,
    ReactiveFormsModule,
  ],
  templateUrl: './account.component.html',
  styleUrl: './account.component.scss',
})
export class AccountComponent implements OnInit {
  authService = inject(AuthService);
  matSnackbar = inject(MatSnackBar);
  router = inject(Router);
  accountDetail$ = this.authService.getDetail();
  
  idUser!: string;
  nome = new FormControl();
  cognome = new FormControl();
  age = new FormControl(new Date());
  phoneNumber = new FormControl();
  email = new FormControl();
  via = new FormControl();
  cap = new FormControl();
  paese = new FormControl();
  provincia = new FormControl();
  comune = new FormControl();

  viaCompleta: any;
  Subscription$?: Membership;
  statoEnum = StatoEnum;
  selectedTabs = new FormControl();
  isModify: boolean = false;

  yearAbbo = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1)
  ).getFullYear();

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private injector: Injector,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');

    var user;

    if (id != null) {
      this.accountDetail$ = this.authService.getUserById(id);

      this.accountDetail$.subscribe((res) => {
        user = res;
        if (res.abbonamentoAttivo) {
          this.authService
            .getAbbonamento(res.id!)
            .subscribe((abbores) => {
              this.Subscription$ = abbores;

              if (this.Subscription$.publicCardId != null) {
                this.Subscription$.publicCardId = this.Subscription$.publicCardId.replaceAll("-", "");
                const joy = this.Subscription$.publicCardId.match(/.{1,4}/g);
                var string = joy?.join(' ');
                this.Subscription$.publicCardId = string as string;
              }
            });
        }
      });
    }
  }

  Openpopup(code: any, title: any, component: any) {
    var _popup = this.dialog.open(component, {
      // panelClass: 'all-dialog-start',
      width: 'auto',
      height: 'auto',
      enterAnimationDuration: '1000ms',
      exitAnimationDuration: '500ms',
      data: {
        title: title,
        code: code,
      },
      injector: this.injector,
    });
    _popup.afterClosed().subscribe((item) => {});
  }

  openModifyUser(user: any) {
    this.selectedTabs.setValue(0);

    this.idUser = user.id;
    this.nome.setValue(user.nome);
    this.cognome.setValue(user.cognome);
    this.age.setValue(parseDMY(user.age));
    this.phoneNumber.setValue(user.phoneNumber);
    this.email.setValue(user.email);
    this.via.setValue(user.via);
    this.cap.setValue(user.cap);
    this.paese.setValue(user.paese);
    this.provincia.setValue(user.provincia);
    this.comune.setValue(user.comune);

    this.isModify = true;
  }

  closeModifyUser() {
    this.selectedTabs.setValue(1);

    this.isModify = false;
  }

  isCanModify(modAtDate: string) {
    const d = parseDMY(modAtDate);
    const t = new Date();

    if (d.getDate() != t.getDate())
      if (d.getMonth() < t.getMonth()) return true;


    return false;
  }


  confirmModifyUser(doacts: boolean = false) {
    if (!doacts) {
      let dialogRef = this.dialog.open(AccountdialogConfirm,{
        // panelClass: 'all-dialog-start',
        width: 'auto',
        height: 'auto',
        enterAnimationDuration: '1000ms',
        exitAnimationDuration: '500ms',
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.confirmModifyUser(true);
        } else {
          this.matSnackbar.open('Annullo modifiche', 'Chiudi', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });
    } else {
      const dataMod: UserDetail = {
        id: this.idUser,
        nome: this.nome.value,
        cognome: this.cognome.value,
        age: this.datePipe.transform(this.age.value, 'dd/MM/yyyy')!,
        phoneNumber: this.phoneNumber.value,
        email: this.email.value,
        via: this.via.value,
        cap: this.cap.value,
        paese: this.paese.value,
        provincia: this.provincia.value,
        comune: this.comune.value
      };
      this.authService.modifyUser(dataMod).subscribe({
        next: (response) => {
          this.matSnackbar.open(response.message, 'Chiudi', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });

          this.isModify = false;
        },
        error: (response) => {
          if (response.IsSuccess == false) {
            this.matSnackbar.open(response.message, 'Chiudi', {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          }
        },
      });

      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', {
        skipLocationChange: true,
      }).then(() => {
        this.router.navigate([currentUrl]);
      })
    }
  }


  resetPsw(id: string) {
    this.Openpopup(id, 'Cambia password', ResetPswComponent);
  }
}

const parseDMY = (s: any) => {
  let [d, m, y] = s.split(/\D/);
  return new Date(y, m - 1, d);
};

@Component({
  selector: 'dialog-account-confirm',
  templateUrl: './account.dialog.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButtonModule,
  ],
})
export class AccountdialogConfirm {
  constructor(@Inject(MAT_DIALOG_DATA) public data: UserDetail) {}
}
