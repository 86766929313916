<div class="main">
  <div class="header">
    <h1 class="text-white">{{ title }}</h1>
  </div>

  <img class="img selectDisable" draggable="false" width="100%" src="assets/images/ForPages/bmwM4.webp" alt="">

  <form [formGroup]="form" (ngSubmit)="sendconfirm()">
    <div mat-dialog-content class="content">

      <mat-form-field appearance="fill" class="max-md:w-full w-2/5 p-2">
        <mat-label>Nome</mat-label>
        <input matInput placeholder="Mario" formControlName="nome" />
      </mat-form-field>

      <mat-form-field appearance="fill" class="max-md:w-full w-5/12 p-2">
        <mat-label>Data di nascita</mat-label>
        <input
          matInput
          [matDatepicker]="datepicker"
          formControlName="eta"
        />
        <mat-hint>DD/MM/YYYY</mat-hint>
        <mat-datepicker-toggle
          matPrefix
          style="color: black"
          [for]="datepicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #datepicker>
          <mat-datepicker-actions>
            <button mat-button matDatepickerCancel>Cancel</button>
            <button mat-raised-button matDatepickerApply>Apply</button>
          </mat-datepicker-actions>
        </mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill" class="max-md:w-full w-2/5 p-2">
        <mat-label>Cognome</mat-label>
        <input matInput placeholder="Rossi" formControlName="cognome" />
      </mat-form-field>

      <mat-form-field appearance="fill" class="max-md:w-full w-4/5 p-2">
        <mat-label>Codice Fiscale / Codice ISS</mat-label>
        <input matInput formControlName="cdFiscale" />
      </mat-form-field>

      <mat-checkbox
          formControlName="acceptAgreement"
          color="warn"
          class="p-3"
          >
          <p class="text-white">Confermo il trattamento dei dati inseriti qui sopra ai fini di profilazione</p>
        </mat-checkbox>
    </div>

    <div mat-dialog-actions class="buttons">
      <a (click)="closepopup()" mat-raised-button color="warn">Close</a>

      <button
        mat-raised-button
        type="submit"
        color="orange"
        style="margin: 1rem"
        [disabled]="!form.valid"
      >
        Conferma
      </button>
      <!-- <button mat-raised-button [mat-dialog-close]="closemessage" color="accent">Cancel</button> -->
    </div>
  </form>
</div>
