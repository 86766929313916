import { Component, inject, Injector, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, RouterLink, RouterModule } from '@angular/router';
import { SubscribeComponent } from '../../components/subscribe/subscribe.component';
import { AuthService } from '../../services/auth.service';
import { RegisterComponent } from '../../components/register/register.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { FormControl, FormControlName } from '@angular/forms';

@Component({
  selector: 'app-abbonamenti',
  standalone: true,
  imports: [MatButtonModule, RouterLink, RouterModule, FooterComponent],
  templateUrl: './abbonamenti.component.html',
  styleUrl: './abbonamenti.component.scss',
})
export class AbbonamentiComponent implements OnInit {
  authService = inject(AuthService);
  user: any;

  activeRoute: ActivatedRoute = inject(ActivatedRoute);
  disablingRoute: Array<string> = new Array<string>();

  constructor(private dialog: MatDialog, private injector: Injector) {}

  ngOnInit(): void {
    this.activeRoute.fragment.subscribe((data) => {
      if (data == null) {
        this.jumpToSection('Gratuito');
        this.jumpToSection('Base');
        this.jumpToSection('Agonistico');
      }
      this.jumpToSection(data);
    });

  }

  Openpopup(code: any, title: any, component: any) {
    var _popup = this.dialog.open(component, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      enterAnimationDuration: '1000ms',
      exitAnimationDuration: '500ms',
      disableClose: true,
      data: {
        title: title,
        code: code,
      },
      injector: this.injector,
    });
    _popup.afterClosed().subscribe((item) => {});
  }

  jumpToSection(section: any) {
    this.disablingRoute.push(section);
  }

  openEventi() {}

  register() {
    this.Openpopup(true, 'Login', RegisterComponent);
  }

  isLoggedIn() {
    return this.authService.isLoggedIn();
  }

  needSubscribe() {
    var user = this.authService.getUserDetail();

    if(user?.abbonamento == "false"){

      this.user = user;      
      return true;
    }

    return false;
  }

  subscribe() {    
    this.Openpopup(this.user!.id, 'Sottoscrivi', SubscribeComponent);
  }

  isMobile() {
    function isMobile() {
      const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      return regex.test(navigator.userAgent);
    }
    
    if (isMobile()) {
      return true
    } else {
      return false
    }
  }
}
