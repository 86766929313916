import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, inject, Inject, OnInit, PLATFORM_ID } from '@angular/core'
import {
  Router,
  RouterOutlet,
  NavigationStart,
  NavigationEnd,
} from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';
import { SEOService } from './services/seo.service';

@Component({
  selector: 'app-root',
  standalone: true,
  providers: [MatDatepickerModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [
    CommonModule,
    RouterOutlet,
    MatButtonModule,
    NavbarComponent,
    FooterComponent,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    FullCalendarModule,
  ],
})
export class AppComponent {
  title = 'SMDRIFT';
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
  };

  constructor(
    private router: Router,
    private seo: SEOService
  ) {
    this.seo.updateTitle();

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationStart) {
        this.seo.cleanActive();

        
      }
    });
  }

}
