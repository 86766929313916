import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AccountComponent } from './pages/account/account.component';
import { authGuard, authGuardSelf } from './guards/auth.guard';
import { UsersComponent } from './pages/users/users.component';
import { roleGuard } from './guards/role.guard';
import { RoleComponent } from './pages/role/role.component';
import { ChisiamoComponent } from './pages/chisiamo/chisiamo.component';
import { EventiSettingsComponent } from './pages/eventi-settings/eventi-settings.component';
import { EventiComponent } from './pages/eventi/eventi.component';
import { NotFoundComponent } from './components/404/404.component';
import { AbbonamentiComponent } from './pages/abbonamenti/abbonamenti.component';
import { ListContactsComponent } from './pages/list-contacts/list-contacts.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { AbbonamentiAdminComponent } from './pages/abbonamenti-admin/abbonamenti-admin.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { GallerySettingsComponent } from './pages/gallery-settings/gallery-settings.component';
import { title } from 'process';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./pages/home/home.component').then((m) => m.HomeComponent),
    data: {
      title: '',
      author: 'Il nostro favoloso Team SMDrift',
      description:
        'Team Drift, corsi, eventi e tanto altro! Entra a far parte del team sempre in espansione e con professionisti',
    },
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: {
      title: 'Privacy',
      description:
        "Le nostre politiche di privacy e policy per l'utilizzo corretto e il funzionamento del sito e del team",
    },
  },
  {
    path: 'chisiamo',
    loadComponent: () =>
      import('./pages/chisiamo/chisiamo.component').then(
        (m) => m.ChisiamoComponent
      ),
    data: {
      title: 'Chi siamo',
      description:
        'Entra e vieni a scoprire chi siamo, dove siamo e come partecipare ai nostri eventi',
    },
  },
  {
    path: 'abbonamenti',
    component: AbbonamentiComponent,
    data: {
      title: 'Abbonamenti',
      description:
        'Sezione dei nostri abbonamenti , da quello base al più competitivo per gare e manifestazioni sportive',
    },
  },
  {
    path: 'gallery',
    loadComponent: () =>
      import('./pages/gallery/gallery.component').then(
        (m) => m.GalleryComponent
      ),
    data: {
      title: 'Galleria',
      description:
        'La nostra galleria delle foto negli eventi e nelle manifestazioni!',
    },
  },
  {
    path: 'gallerysettings',
    loadComponent: () =>
      import('./pages/gallery-settings/gallery-settings.component').then(
        (m) => m.GallerySettingsComponent
      ),
    canActivate: [authGuard],
    data: {
      title: 'Galleria admin',
      roles: ['Admin'],
    },
  },
  {
    path: 'eventisettings',
    loadComponent: () =>
      import('./pages/eventi-settings/eventi-settings.component').then(
        (m) => m.EventiSettingsComponent
      ),
    canActivate: [authGuard],
    data: {
      title: 'Eventi admin',
      roles: ['Admin'],
    },
  },
  {
    path: 'eventi',
    component: EventiComponent,
    canActivate: [authGuard],
    data: {
      title: 'Eventi',
    },
  },
  {
    path: 'account/:id',
    component: AccountComponent,
    canActivate: [authGuard, authGuardSelf],
    data: {
      title: 'Profilo',
    },
  },
  {
    path: 'users',
    loadComponent: () =>
      import('./pages/users/users.component').then((m) => m.UsersComponent),
    canActivate: [roleGuard],
    data: {
      title: 'Profili admin',
      roles: ['Admin'],
    },
  },
  {
    path: 'contactlist',
    loadComponent: () =>
      import('./pages/list-contacts/list-contacts.component').then(
        (m) => m.ListContactsComponent
      ),
    canActivate: [roleGuard],
    data: {
      title: 'Contatti admin',
      roles: ['Admin'],
    },
  },
  {
    path: 'abboAdmin',
    loadComponent: () =>
      import('./pages/abbonamenti-admin/abbonamenti-admin.component').then(
        (m) => m.AbbonamentiAdminComponent
      ),
    canActivate: [roleGuard],
    data: {
      title: 'Abbonamenti admin',
      roles: ['Admin'],
    },
  },
  {
    path: 'roles',
    loadComponent: () =>
      import('./pages/role/role.component').then((m) => m.RoleComponent),
    canActivate: [roleGuard],
    data: {
      title: 'Ruoli admin',
      roles: ['Admin'],
    },
  },
  {
    path: 'api/**',
    pathMatch: 'full',
    redirectTo: '',
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
