<div class="animation-wrapper">
  <div class="particle particle-1"></div>
  <div class="particle particle-2"></div>
  <div class="particle particle-3"></div>
  <div class="particle particle-4"></div>
</div>

<div class="hero">
  <div class="container" hidden>
    <h1 class="main-title">Tutti i nostri eventi</h1>

    <swiper-container
      slides-per-view="3"
      speed="500"
      loop="true"
      navigation="true"
      class="max-h-80"
    >
      @for(item of events$; track $index){
      <swiper-slide style="height: 100%">
        <div class="slide">
          <img
            class="image w-full h-full"
            [src]="
              item.path != null ? apiUrl + 'images/event/' + item.path : ''
            "
            alt=""
          />
          <div class="swiper-text">
            <h2 class="title">{{ item.nome | uppercase }}</h2>
            <p class="data">{{ item.data }}</p>
          </div>
        </div>
      </swiper-slide>
      }
    </swiper-container>
  </div>

  <div class="events mx-auto w-full">
    <div class="single-event flex relative" *ngFor="let event of events$">
      <div class="event-left w-full max-w-lg">
        <img
          class="image w-full"
          [src]="apiUrl + 'images/event/' + event.path"
          alt=""
        />
      </div>

      <div
        class="event-right w-full max-w-xl mx-auto gap-y-6 content-center flex flex-col"
      >
        <div class="flex flex-col gap-4">
          <h1 class="font-bold text-4xl">{{ event.nome }}</h1>
          <div class="flex gap-2 ml-4">
            <mat-icon class="mat-18">calendar_month</mat-icon>
            <h2>{{ parseDMY(event.data) | date : "dd MMMM yyyy" }}</h2>
          </div>
        </div>

        <div class="animated-paragr">
          <p class="abl">{{ event.descrizione }}</p>

          <div class="abr">
            <div
              [matTooltip]="
                !canLogEvent
                  ? 'Devi aver comprato un abbonamento per poterti registrare agli eventi'
                  : 'Invia richiesta di ammissione all\'evento'
              "
              [matTooltipDisabled]="canLogEvent"
            >
              <a
                class="relative"
                mat-raised-button
                [disabled]="!canLogEvent"
                (click)="scroll(calendar, event.id)"
                color="orange"
              >
                <span>Iscriviti all'evento</span></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="calendar text-white flex">
    <div class="left">
      <full-calendar
        id="myCalendar"
        [options]="calendarOptions"
      ></full-calendar>
    </div>
    <div class="right">
      <div class="header">
        <h2>Evento in dettaglio</h2>
      </div>

      <div
        id="eventoshow"
        class="event text-white flex flex-col"
        [hidden]="!clean"
      >
        <img
          class="image w-full"
          [src]="apiUrl + 'images/event/' + singleEvent?.path"
          [hidden]="!clean"
          alt=""
        />
        <div class="text">
          <h2 class="title text-center" [hidden]="!clean">
            <span class="font-bold text-2xl">Nome:</span><br />
            {{ singleEvent?.nome }}
          </h2>
          <p class="data text-xl text-center pt-2" [hidden]="!clean">
            {{ singleEvent?.data }}
          </p>
          <p class="description text-center" [hidden]="!clean">
            <span class="font-bold text-2xl">Descrizione:</span><br />
            {{ singleEvent?.descrizione }}
          </p>

          <div #calendar class="button p-6 text-center" [hidden]="!clean">
            <a
              mat-raised-button
              (click)="openForm(singleEvent?.id)"
              color="orange"
              [disabled]="isAlready || !canLogEvent"
              >Iscriviti all'evento</a
            >
            <p [hidden]="canLogEvent">
              Devi aver comprato un abbonamento per poterti registrare agli
              eventi
            </p>
            <p [hidden]="!isAlready">Ti sei già registrato all'evento</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer />
